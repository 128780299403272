import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import NodeJsImg from "../../assets/images/node-js.png";
import PhpImg from "../../assets/images/php_circle.png";
import AngularLogo from "../../assets/images/angular.png";
import JsLogo from "../../assets/images/js-logo.png";
import SpringBootImg from "../../assets/images/spring-boot.png";
import ReactJs from "../../assets/images/react-js.png";
import NestJS from "../../assets/images/nestjs.png";
import LaravelImg from "../../assets/images/laravel.png";

const SectionFour = () => {
  const [counted, setCounted] = useState(false);
  const countersRef = useRef([]);

  useEffect(() => {
    AOS.init({ duration: 2000 });

    const handleScroll = () => {
      if (countersRef.current.length > 0) {
        const oTop = countersRef.current[0].offsetTop - window.innerHeight;
        if (!counted && window.scrollY > oTop) {
          countersRef.current.forEach((counter) => {
            const updateCount = () => {
              const target = +counter.getAttribute("data-count");
              const count = +counter.innerText;
              const speed = 200; // Change this value to adjust the speed
              const increment = target / speed;

              if (count < target) {
                counter.innerText = Math.ceil(count + increment);
                setTimeout(updateCount, 10);
              } else {
                counter.innerText = target;
              }
            };

            updateCount();
          });

          setCounted(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [counted]);
  return (
    <>
      {/* <!-- ====================================== Section Four ===================================== --> */}
      <section className="section-four overflow-hidden">
      <div className="container">
          <p className="our-services my_skills" data-aos="fade-up">
            MY SKILLS
          </p>
          <h2 className="our-solution my-expertise" data-aos="fade-down">
            My Expertise.
          </h2>
          <div className="skill-type-main">
            <div
              className="skills-box-main"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <img src={PhpImg} alt="php" />
              <div className="counter">
                <h3
                  className="count count2"
                  data-count="96"
                  ref={(el) => (countersRef.current[1] = el)}
                >
                  0
                </h3>
                <p>PHP</p>
              </div>
            </div>
            <div
              className="skills-box-main"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <img src={SpringBootImg} alt="spring-boot" />
              <div className="counter">
                <h3
                  className="count count2"
                  data-count="86"
                  ref={(el) => (countersRef.current[4] = el)}
                >
                  0
                </h3>
                <p>SPRINGBOOT</p>
              </div>
            </div>
            <div
              className="skills-box-main"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <img src={ReactJs} alt="react-js" />
              <div className="counter">
                <h3
                  className="count count2"
                  data-count="90"
                  ref={(el) => (countersRef.current[5] = el)}
                >
                  0
                </h3>
                <p>REACT JS</p>
              </div>
            </div>
            <div
              className="skills-box-main"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <img src={NestJS} alt="nestjs" />
              <div className="counter">
                <h3
                  className="count count2"
                  data-count="95"
                  ref={(el) => (countersRef.current[6] = el)}
                >
                  0
                </h3>
                <p>NEST JS</p>
              </div>
            </div>
            <div
              className="skills-box-main"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <img src={LaravelImg} alt="laravel" />
              <div className="counter">
                <h3
                  className="count count2"
                  data-count="93"
                  ref={(el) => (countersRef.current[7] = el)}
                >
                  0
                </h3>
                <p>LARAVEL</p>
              </div>
            </div>
            <div
            className="skills-box-main"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <img src={AngularLogo} alt="angular-logo" />
            <div className="counter">
              <h3
                className="count count2"
                data-count="95"
                ref={(el) => (countersRef.current[2] = el)}
              >
                0
              </h3>
              <p>Angular</p>
            </div>
        </div>
        <div
          className="skills-box-main"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <img src={JsLogo} alt="js-logo" />
          <div className="counter">
            <h3
              className="count count2"
              data-count="97"
              ref={(el) => (countersRef.current[3] = el)}
            >
              0
            </h3>
            <p>JAVASCRIPT</p>
          </div>
        </div>
        
            <div
              className="skills-box-main"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <img src={NodeJsImg} alt="node-js" />
              <div className="counter">
                <h3
                  className="count count2"
                  data-count="95"
                  ref={(el) => (countersRef.current[0] = el)}
                >
                  0
                </h3>
                <p>NODE JS</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Four End ===================================== --> */}
    </>
  );
};
export default SectionFour;
